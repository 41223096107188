(function () {
  'use strict';

  /* @ngdoc object
   * @name entitat.informacio
   * @description
   *
   */
  angular
  .module('neo.home.entitats.entitat.informacio', [
    'ui.router',
    'ngMaterial',
    'neo.home.entitats.entitat.informacio.image',
    'neo.home.entitats.entitat.informacio.people'
  ]);
}());
